/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanDoctorSmiling from '../images/heroes/woman-and-doctor-smiling.jpg'
import partBFormula from '../images/icons/part-b-formula.svg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import CobraBanner from '../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-aet-partb']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'What is Medicare Part B | Call 855-200-5699 to learn more | Aetna Medicare',
          description:
            'Learn about Medicare Part B, commonly referred to as hospital insurance, to understand what it covers, who is eligible, when to enroll, cost & more call 855-200-5699',
        },
        path: '/medicare-part-b',
        promoCode: '120767',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={womanDoctorSmiling}
                  alt="elderly woman and doctor in doctor's office, smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent
          alignMainContent="center"
          backgroundColor="light-gray"
          mainContent={
            <>
              <Typography variant="h2">
                What Is Aetna Medicare Part B?
              </Typography>
              <img
                src={partBFormula}
                alt="medical insurance + preventative care = Medicare Part B formula illustration"
                className="margin-bottom-md"
              />
              <Typography variant="body">
                Medicare Part B is half of a national health care program known
                as “Original Medicare." The other half of Original Medicare is
                called Medicare Part A. Like Medicare Part A, Medicare Part B is
                subsidized and managed by the federal government. Most people
                who are 65 or older are eligible for Medicare Part B.
              </Typography>
              <Typography variant="h5" color="primary">
                Learn more about Medicare Part B coverage today.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-checklist.svg"
                alt="checklist icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                What Does Medicare Part B Cover?
              </Typography>
              <Typography variant="body">
                Coverage varies depending on your area, but generally Medicare
                Part B covers two types of services:
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title: 'Medically necessary services',
                      content: (
                        <>
                          <Typography>
                            This includes any outpatient health care service or
                            supply that you may need to treat or diagnose an
                            illness or injury.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'Preventative services',
                      content: (
                        <>
                          <Typography>
                            This includes any health care service or supply that
                            you may need to help prevent an illness or injury or
                            detect it early on when treatment will be more
                            effective.
                          </Typography>
                          <Typography>
                            Some examples of services or supplies that may be
                            covered by Medicare Part B include:
                          </Typography>
                          <List>
                            <ListItem>
                              Outpatient doctor’s visits and surgeries
                            </ListItem>
                            <ListItem>Ambulance services</ListItem>
                            <ListItem>Clinical research studies</ListItem>
                            <ListItem>Mental health services</ListItem>
                            <ListItem>
                              Limited outpatient prescription drugs
                            </ListItem>
                          </List>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
              <Typography variant="body">
                To find out whether a health care service you need is covered by
                Medicare Part B, talk to a licensed agent. Your agent can tell
                you whether the service is covered by Medicare and which health
                care providers offer it in your area.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-dollar.svg"
                alt="dollar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                What Is the Medicare Part B Premium?
              </Typography>
              <Typography variant="body">
                According to Medicare’s official website, the standard premium
                amount for Medicare Part B coverage is $144.60 a month. However,
                depending on your income and whether you’re currently receiving
                Social Security benefits, your amount may be higher or lower.
              </Typography>
              <Typography variant="h5">
                Medicare Part B Premiums, 2020
              </Typography>
              <div className="comparison-table">
                <table className="table-main align-text-left">
                  <thead>
                    <td>Your Annual Income</td>
                    <td></td>
                    <td>Monthly Amount</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>If you filed an individual tax return</td>
                      <td>If you filed a joint tax return</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>$87,000 or less</td>
                      <td>$174,000 or less</td>
                      <td>$144.60</td>
                    </tr>
                    <tr>
                      <td>$87,000–$109,000</td>
                      <td>$174,000–$218,000</td>
                      <td>$202.40</td>
                    </tr>
                    <tr>
                      <td>$109,000–$136,000</td>
                      <td>$218,000–$272,000</td>
                      <td>$289.20</td>
                    </tr>
                    <tr>
                      <td>$136,000–$163,000</td>
                      <td>$272,000–$326,000</td>
                      <td>$376.20</td>
                    </tr>
                    <tr>
                      <td>$163,000–$500,000</td>
                      <td>$326,000–$750,000</td>
                      <td>$462.70</td>
                    </tr>
                    <tr>
                      <td>$500,000 or greater</td>
                      <td>$750,000 or greater</td>
                      <td>$491.60</td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="body" className="table-after">
                  Note: If you are married but file a separate tax return, you
                  pay $144.60 a month if your yearly income is $87,000 or less.
                  You pay $462.70 a month if you earn between $87,000 and
                  $413,000 a year and $491.60 a month if you earn $413,000 or
                  more a year.
                </Typography>
              </div>
              <Typography variant="body">
                Typically, your Medicare Part B premiums are automatically
                deducted from your Social Security benefits if you receive them.
                Just keep in mind that your premium amount may change from year
                to year.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-dollar.svg"
                alt="dollar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Is There a Medicare Part B Deductible?
              </Typography>
              <Typography variant="body">
                In 2020, the annual deductible for Medicare Part B medical
                insurance is $198. You may also have to pay a 20 percent
                coinsurance for doctor’s visits and other health care services
                after you meet your deductible.
              </Typography>
              <Typography variant="h5" color="primary">
                Here are some examples of what you might expect to pay for
                certain medical services:
              </Typography>
              <div className="comparison-table">
                <table className="table-main align-text-left">
                  <thead>
                    <td>Service</td>
                    <td>You pay …</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Medical</td>
                      <td>
                        <List>
                          <ListItem>
                            20% of the Medicare-approved amount for most
                            doctor's services—including while you're staying at
                            a hospital—as well as outpatient therapy and durable
                            medical equipment (DME)
                          </ListItem>
                        </List>
                      </td>
                    </tr>
                    <tr>
                      <td>Clinical laboratory</td>
                      <td>
                        <List>
                          <ListItem>$0 for Medicare-approved services</ListItem>
                        </List>
                      </td>
                    </tr>
                    <tr>
                      <td>Home health care</td>
                      <td>
                        <List>
                          <ListItem>$0 for Medicare-approved services</ListItem>
                          <ListItem>
                            20% coinsurance of the Medicare-approved amount for
                            durable medical equipment
                          </ListItem>
                        </List>
                      </td>
                    </tr>
                    <tr>
                      <td>Outpatient mental health</td>
                      <td>
                        <List>
                          <ListItem>
                            $0 for your annual depression screening (if your
                            doctor accepts Medicare)
                          </ListItem>
                          <ListItem>
                            20% coinsurance of the Medicare-approved amount to
                            diagnose or treat your condition
                          </ListItem>
                        </List>
                      </td>
                    </tr>
                    <tr>
                      <td>Outpatient hospital services</td>
                      <td>
                        <List>
                          <ListItem>
                            20% of the Medicare-approved amount for your
                            doctor's or other health care provider's services
                          </ListItem>
                          <ListItem>
                            A copayment for all other services
                          </ListItem>
                        </List>
                      </td>
                    </tr>
                    <tr>
                      <td>Preventative care and health screenings</td>
                      <td>
                        <List>
                          <ListItem>
                            $0 for all Medicare-covered services
                          </ListItem>
                        </List>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="body" className="table-after">
                  Note: If you are married but file a separate tax return, you
                  pay $144.60 a month if your yearly income is $87,000 or less.
                  You pay $462.70 a month if you earn between $87,000 and
                  $413,000 a year and $491.60 a month if you earn $413,000 or
                  more a year.
                </Typography>
              </div>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-heart.svg"
                alt="location icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                Am I Eligible for Medicare Part B?
              </Typography>
              <Typography variant="body">
                If you’re eligible for “premium-free” Medicare Part A, chances
                are you already qualify to receive Medicare Part B benefits. All
                you have to do is enroll in Part B and start paying the monthly
                premium.
              </Typography>
              <Typography variant="body">
                If you’re not eligible for premium-free Medicare Part A,
                however, you may be eligible for Medicare Part B coverage if the
                following applies to you:
              </Typography>
              <List>
                <ListItem>You are 65 years old or older</ListItem>
                <ListItem>
                  You are a US citizen or legal permanent resident who has been
                  living in the US for at least five years in a row
                </ListItem>
              </List>
              <Typography variant="body">
                If you are disabled, you may also qualify for enrollment in
                Medicare Part B benefits. If you are under 65 years old, you may
                qualify for enrollment if one of the following situations
                describes you:
              </Typography>
              <List>
                <ListItem>
                  You receive Social Security disability benefits
                </ListItem>
                <ListItem>
                  You receive Railroad Retirement board (RRB) disability
                  benefits
                </ListItem>
                <ListItem>
                  You suffer from end-stage renal disease (ESRD) or amyotrophic
                  lateral sclerosis (also known as ALS or Lou Gehrig’s disease)
                </ListItem>
              </List>
              <Typography variant="body">
                Keep in mind, you will be eligible for Medicare Part B benefits
                only after 24 consecutive months of receiving Social Security or
                RRB disability benefits.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <CobraBanner />

        <VariableContent className="bg-light-gray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-calendar.svg"
                alt="location icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">
                When Can I Enroll in Medicare Part B?
              </Typography>
              <Typography variant="body">
                If you’re enrolling in Medicare for the first time, you can
                enroll during your Initial Enrollment Period (IEP). Your Initial
                Enrollment Period begins three months before the month of your
                65th birthday and ends three months after your birth month.
              </Typography>
              <Typography variant="body">
                If you’re already on Original Medicare and want to enroll in an{' '}
                <a href="/advantage">Medicare Advantage plan</a>, you can do so
                during the Annual Enrollment Period (AEP), which starts October
                15 and ends December 7 every year.
              </Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in an
                Medicare Advantage plan during a Special Enrollment Period. For
                example, if you’ve recently moved or if you’re currently living
                in a nursing home. Talk to one of our licensed agents today to
                see if your life circumstance applies.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>
        <CobraBanner />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
